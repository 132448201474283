import React from "react";
import Input from "../Global/Input";
import { PrimaryButton } from "../Global/Button";
import AudioAnimation from "../../assets/audio-animation.png";

export default function PatientInformation({
  patientName,
  setPatientName,
  startRecording,
  setIsRecording,
  setSelectedLanguage,
  setIsPatientForm,
  selectedOption,
  handleOptionChange,
  setSelectedSpecialization,
}) {
  return (
    <div className="flex gap-2 flex-col text-center items-center justify-center">
      <div className="flex item-center justify-center">
        <img src={AudioAnimation} alt="animation" />
      </div>
      <p className="font-semibold">Start by saving patient’s name</p>
      <div>
        <p className="text-sm text-customBlack-text ">
          Any recordings done here will be saved in recordings
        </p>

        <p className="text-sm text-customBlack-text ">section on the left.</p>
        <p className="text-sm text-customBlack-text ">
          Hit the record button once you type in the patient name
        </p>
      </div>
      <div className="w-full">
        <div className="flex">
          <select
            id="language"
            name="Language"
            className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            <option value="en-US">English</option>
            <option value="zh-HK">Cantonese</option>
            <option value="fr-CA">French</option>
            <option value="de">German</option>
            <option value="hi">Hindi</option>
            <option value="it">Italian</option>
            <option value="zh-TW">Mandarin</option>
            <option value="pt">Portugese</option>
            <option value="multi">Spanish</option>
            {/* <option value="ur-PK">Urdu</option>
            <option value="ar-SA">Arabic</option> */}
            {/* <option value="pa-IN">Punjabi</option> */}
          </select>
        </div>

        <Input
          label="Patient's name"
          type="text"
          placeholder="Enter patients name"
          value={patientName}
          handleOnChange={(e) => setPatientName(e.target.value)}
          isRequired={true}
        />

        <>
          <div className="flex gap-2">
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="SOAP"
                name="options"
                value="SOAP"
                checked={selectedOption === "SOAP"}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label
                htmlFor="SOAP"
                className="ml-2 block text-sm text-gray-900"
              >
                SOAP Notes
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="consultation"
                name="options"
                value="consultation"
                checked={selectedOption === "consultation"}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label
                htmlFor="consultation"
                className="ml-2 block text-sm text-gray-900"
              >
                Consultation Notes
              </label>
            </div>
          </div>
          {selectedOption === "consultation" && (
            <div className="flex">
              <select
                id="occupation"
                name="occupation"
                className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
                onChange={(e) => setSelectedSpecialization(e.target.value)}
              >
                <option value="">Select Specialization</option>
                <option value="internal_medicine">Internal Medicine</option>
                <option value="general_surgery">General Surgery</option>
                <option value="anesthesiology">Anesthesiology</option>
                <option value="pediatrics">Pediatrics</option>
                <option value="obstetrics_gynecology">
                  Obstetrics & Gynecology
                </option>
                <option value="psychiatry">Psychiatry</option>
                <option value="ophthalmology">Ophthalmology</option>
                <option value="otolaryngology">Otolaryngology (ENT)</option>
                <option value="orthopedic_surgery">Orthopedic Surgery</option>
                <option value="dermatology">Dermatology</option>
              </select>
            </div>
          )}
        </>
        <PrimaryButton
          label={"Record"}
          onClick={async () => {
            await startRecording();
            // setPauseRecording(true);
            setIsRecording(true);
            setIsPatientForm(true);
          }}
          isDisabled={!!!patientName}
        />
      </div>
    </div>
  );
}
