import { create } from "zustand";

const useUserStore = create((set) => ({
  uid: {},
  setUid: (uid) => set({ uid }),
  currentUser: {},
  setCurrentUser: (currentUser) => set({ currentUser }),
}));

export default useUserStore;
