import React from "react";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import RecordIcon from "../../assets/record-icon.png";
import RecordGreenIcon from "../../assets/record-icon-green.png";

export default function TranscriptSection({
  selectedRecord,
  pauseRecording,
  userTranscript,
  formattedPromptSOAP,
  resetRecordingState,
  handleTranscriptChange,
  handlePause,
  handleSubmitResponse,
}) {
  const drawWaveform = (analyserNode, dataArr) => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");

    const draw = () => {
      const drawVisual = requestAnimationFrame(draw);
      setAnimationId(drawVisual);

      analyserNode.getByteTimeDomainData(dataArr);

      canvasCtx.fillStyle = "#f3f4f6"; // Tailwind gray-100
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "#4f46e5"; // Tailwind indigo-600

      canvasCtx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / dataArr.length;
      let x = 0;

      for (let i = 0; i < dataArr.length; i++) {
        const v = dataArr[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  };

  return (
    <div
      className={`w-full 
  ${formattedPromptSOAP ? "md:w-[66%]" : "md:w-[66%] "}
  border-r h-screen `}
    >
      <textarea
        value={userTranscript}
        className="border-none w-full"
        rows="10"
        placeholder="Transcription will appear here..."
        onChange={handleTranscriptChange}
      />

      <div className="flex flex-col items-center">
        <div className="flex gap-2 items-center">
          <img
            src={pauseRecording ? RecordGreenIcon : RecordIcon}
            alt="record icon"
            className="cursor-pointer"
            onClick={handlePause}
          />
          <p className="text-lg">
            {!pauseRecording
              ? "Recording Paused(Click button to start recording)"
              : "Currently Recording(Click button to stop recording)"}
          </p>
        </div>
        <p className="text-customBlack-text">
          {pauseRecording
            ? "Press the record icon to stop recording"
            : "Press the record icon to start recording"}
        </p>
      </div>
      <div className="flex gap-2 items-center justify-center">
        {!selectedRecord?.id && (
          <SecondaryButton
            label={"Start new Recording"}
            onClick={resetRecordingState}
          />
        )}
        <PrimaryButton
          label={"Generate the Note"}
          onClick={handleSubmitResponse}
        />
      </div>
    </div>
  );
}
