import React, { useState } from "react";
import Input from "../Global/Input";
import PhoneInput from "react-phone-number-input";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import useUserStore from "../../utils/userStore";
import "../../styles/Auth.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordInput from "../Global/PasswordInput";
import CryptoJS from "crypto-js";
import ApplyCoupon from "./ApplyCoupon";

const userDataSchema = Yup.object({
  name: Yup.string(),
  occupation: Yup.string(),
  phone: Yup.string(),
  clinicName: Yup.string(),
  email: Yup.string().email("Invalid email address"),
  clinicAddress: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zip: Yup.string(),
});

export default function Settings() {
  const currentUser = useUserStore((state) => state.currentUser);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: currentUser.name,
      occupation: currentUser.occupation,
      phone: currentUser.phone_number,
      clinicName: currentUser.clinic_name,
      email: currentUser.email,
      clinicAddress: currentUser.clinic_address,
      city: currentUser.city,
      state: currentUser.state,
      zip: currentUser.zip,
    },
    validationSchema: userDataSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BE_DOMAIN}/user/update`,
          {
            user_id: currentUser.user_id,
            name: values.name,
            email: values.email,
            occupation: values.occupation,
            clinic_name: values.clinicName,
            clinic_address: values.clinicAddress,
            city: values.city,
            state: values.state,
            zip: values.zip,
            headers: {
              "Content-Type": "multipart/form-data",
              // 'Authorization': `Bearer ${token}`
            },
          }
        );

        if (response.data) {
          toast.success("Data has been updated successfully");
        }
      } catch (error) {
        console.error("Error updating data", error);
        toast.error("Error updating data");
      }
    },
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteAccount = () => {
    navigate("/delete-account");
  };

  return (
    <div className="bg-customBlack-grey  p-8 flex flex-col gap-8 justify-center items-center">
      <form
        onSubmit={formik.handleSubmit}
        className=" space-y-4 bg-white p-4 rounded-lg border border-customBlack-light md:w-1/2 w-full"
      >
        <div className="border-b border-customBlack-light pb-3">
          <p className="text-xl font-semibold">Personal Information</p>
          <p className="text-p font-p text-customBlack-secondary">
            Update your personal details here.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-b border-customBlack-light pb-4">
          <div>
            <Input
              name="name"
              label="Name"
              type="text"
              placeholder="Enter your name"
              props={{ ...formik.getFieldProps("name") }}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-sm">{formik.errors.name}</div>
            ) : null}
          </div>

          <div>
            <p>Occupation</p>
            <select
              id="occupation"
              name="occupation"
              className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
              {...formik.getFieldProps("occupation")}
            >
              <option value="">Select Occupation</option>
              <option value="doctor">Doctor</option>
              <option value="nurse">Nurse</option>
              <option value="other">Other</option>
            </select>
            {formik.touched.occupation && formik.errors.occupation ? (
              <div className="text-red-500 text-sm">
                {formik.errors.occupation}
              </div>
            ) : null}
          </div>

          <div>
            <p>Phone Number</p>

            <PhoneInput
              international
              countryCallingCodeEditable={true}
              defaultCountry="CA"
              placeholder={"Phone number"}
              value={formik.values.phone}
              disabled
              className="w-full p-2.5 mb-1.5 mt-1.5 bg-gray-100 border border-gray-300 rounded-lg cursor-not-allowed disabled:cursor-not-allowed"
            />
          </div>

          <div>
            <Input
              name="clinicName"
              label="Clinic Name"
              type="text"
              placeholder="Enter clinic name"
              props={{ ...formik.getFieldProps("clinicName") }}
            />
            {formik.touched.clinicName && formik.errors.clinicName ? (
              <div className="text-red-500 text-sm">
                {formik.errors.clinicName}
              </div>
            ) : null}
          </div>

          <div>
            <Input
              name="email"
              label="Email"
              type="email"
              placeholder="Enter your email"
              props={{ ...formik.getFieldProps("email") }}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            ) : null}
          </div>

          <div>
            <Input
              name="clinicAddress"
              label="Clinic Address"
              type="text"
              placeholder="Enter your clinic address"
              props={{ ...formik.getFieldProps("clinicAddress") }}
            />
            {formik.touched.clinicAddress && formik.errors.clinicAddress ? (
              <div className="text-red-500 text-sm">
                {formik.errors.clinicAddress}
              </div>
            ) : null}
          </div>

          <div>
            <Input
              name="city"
              label="City"
              type="text"
              props={{ ...formik.getFieldProps("city") }}
            />
            {formik.touched.city && formik.errors.city ? (
              <div className="text-red-500 text-sm">{formik.errors.city}</div>
            ) : null}
          </div>

          <div>
            <Input
              name="state"
              label="State"
              type="text"
              props={{ ...formik.getFieldProps("state") }}
            />
            {formik.touched.state && formik.errors.state ? (
              <div className="text-red-500 text-sm">{formik.errors.state}</div>
            ) : null}
          </div>

          <div>
            <Input
              name="zip"
              label="Zip"
              type="number"
              props={{ ...formik.getFieldProps("zip") }}
            />
            {formik.touched.zip && formik.errors.zip ? (
              <div className="text-red-500 text-sm">{formik.errors.zip}</div>
            ) : null}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <p
            className="text-sm font-semibold text-customRed-text cursor-pointer"
            onClick={handleOpenModal}
          >
            Change password
          </p>

          <div className="flex gap-2">
            <SecondaryButton label={"Cancel"} />
            <PrimaryButton label={"Save Changes"} />
          </div>
        </div>
      </form>
      <ChangePasswordModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <div className="bg-white p-4 rounded-lg border border-customBlack-light md:w-1/2 w-full">
        <p className="text-xl font-semibold py-4">Plan</p>
        {currentUser?.plan ? (
          <div className="flex justify-between items-center border-b border-t py-4 mb-4 border-customBlack-light">
            <div>
              <p className="text-lg font-semibold">Promotion Plan</p>
              {!currentUser?.plan === "0" && (
                <p className="text-base font-normal text-customBlack-text">
                  Billed monthly
                </p>
              )}
            </div>
            <p className="text-base font-normal">
              {currentUser?.plan === "0" ? "Free Plan" : currentUser?.plan}
            </p>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <p className="text-base font-normal">Pick a plan that suits you</p>
            <PrimaryButton
              label={"Get started"}
              onClick={() => navigate("/pricing")}
            />
          </div>
        )}

        <div className="flex justify-between items-center">
          {/* <p className="text-sm font-semibold text-customRed-text">
            Cancel Subscription
            </p> */}
          {/* <PrimaryButton label={"Save Changes"} /> */}
        </div>
      </div>
      {/* <ApplyCoupon userId={currentUser.user_id} /> */}
      {currentUser.promotion_code && (
        <div className="bg-white p-4 rounded-lg border border-customBlack-light md:w-1/2 w-full">
          <div className="pb-4 border-b border-customBlack-light">
            <p className="text-xl font-semibold py-4">Group discount code</p>
            <p className="text-base font-normal text-customBlack-text">
              Here is your group discount code to share with other doctors for
              group discounts
            </p>
          </div>
          <p className="text-[48px] cursor-pointer font-semibold text-customBlack-text text-center">
            {currentUser?.promotion_code}
          </p>
        </div>
      )}
      {/* <div className="flex">
        <p onClick={handleDeleteAccount}>Delete Account</p>
      </div> */}
      <ToastContainer />
    </div>
  );
}

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const currentUser = useUserStore((state) => state.currentUser);

  const handleChangePassword = async () => {
    const hashedOldPassword = CryptoJS.SHA256(oldPassword).toString();
    const hashedNewPassword = CryptoJS.SHA256(newPassword).toString();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/user/change-password`,
        {
          userId: currentUser.user_id,
          oldPassword: hashedOldPassword,
          newPassword: hashedNewPassword,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        }
      );

      if (response.data) {
        toast.success(response.data);
        onClose();
      }
    } catch (error) {
      toast.error("Error while updating password");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 shadow-md w-full max-w-md rounded-lg">
        <h2 className="text-2xl mb-4">Change Password</h2>
        <PasswordInput
          label="Old Password"
          placeholder="Enter old password"
          value={oldPassword}
          handleOnChange={(e) => setOldPassword(e.target.value)}
          isRequired={true}
        />
        <PasswordInput
          label="New Password"
          placeholder="Enter new password"
          value={newPassword}
          handleOnChange={(e) => setNewPassword(e.target.value)}
          isRequired={true}
        />
        <div className="flex justify-end gap-2">
          <SecondaryButton onClick={onClose} label={"Cancel"} />
          <PrimaryButton
            onClick={handleChangePassword}
            label={"Change Password"}
          />
        </div>
      </div>
    </div>
  );
};
